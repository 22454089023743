import  db  from "./firebase";
import React, { useState, useEffect } from "react";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);



  
  const handleSubmit = (e) => {

    e.preventDefault();
    setLoader(true);

    db.collection("contacts")
      .add({
        name: name,
        email: email,
        subject: subject,
        message: message,

      })
      .then(() => {
        setLoader(false);
        alert("Your message has been submitted👍");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

return(
  <div>
    <form className="php-email-form" onSubmit={handleSubmit}>

                <div className="row">

                  <div className="col-md-6 form-group">

                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />

                  </div>

                  <div className="col-md-6 form-group mt-3 mt-md-0">

                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                  </div>

                </div>

                <div className="form-group mt-3">

                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required=""
                       value={subject}
                       onChange={(e) => setSubject(e.target.value)}
                  />

                </div>

                <div className="form-group mt-3">

                  <textarea className="form-control" name="message" rows="5" placeholder="Message" required=""
                       value={message}
                       onChange={(e) => setMessage(e.target.value)}
                  ></textarea>

                </div>

                <div className="my-3">

                  <div className="loading">Loading</div>

                  <div className="error-message"></div>

                  <div className="sent-message">

                    Your message has been sent. Thank you!

                  </div>

                </div>

                <div className="text-center">

                  <button type="submit">Send Message</button>

                </div>

              </form>
              </div>
);
}

export default ContactForm;