import './App.css';
import ContactForm from './components/ContactForm';

function App() {
  return (
    <div className="App">
     <ContactForm/>
    </div>
  );
}

export default App;
