import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCBRfkLaKNwjHjz-SebwLlRcZMBrSOWs9s",
  authDomain: "mycontact-87842.firebaseapp.com",
  databaseURL: "https://mycontact-87842-default-rtdb.firebaseio.com",
  projectId: "mycontact-87842",
  storageBucket: "mycontact-87842.appspot.com",
  messagingSenderId: "450845598575",
  appId: "1:450845598575:web:213ffef2084309ac2bbe9a",
  measurementId: "G-0RFYSSK052"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

 // var db = firebaseApp.firebaseConfig();
 var db = firebase.firestore();
  
 export default db;
  